import './ui-elements'
import './form'
import './popup'
import './animation'

// import './tab'


$(function () {

			$('.js-nav-btn').on('click',function(){
				var $this = $(this);
				var navBlock = $('.js-nav-block');
				$this.toggleClass('burger_active');
				navBlock.slideToggle();
			});

			$('.js-nav-btn').click(function(){
					let menu=$(this);
					menu.toggleClass("active");
					if (menu.hasClass('active')) {
						$('.header-col--nav').animate({"left":"0px"});					
					} else {
						$('.header-col--nav').animate({"left":"-100%"});						
					} 		 
			});

			$('.cat-menu a').hover(function(){
				let url=$(this).data('img');
				console.log(url);
				// $('.banner__img').css('background-image', 'url(' + url + ')');
				$('.cat-menu li').removeClass('active');
				$(this).parent().addClass('active');
				$('.banner__img').animate({
					opacity: 0
				}, 100, function() {
						$(this).css("background-image", "url(" + url + ")").promise().done(function(){
								$(this).animate({
										opacity: 1
								}, 200) 
						});    
				});
			});

			$(window).scroll(function(){
				var navblock= $('header');				
				if($(this).scrollTop()>0){
					navblock.addClass('fixed');
				} else{
					navblock.removeClass('fixed');
				}
			});
			
			$(window).on('resize load', function(){
				if (($(".js-product-slider").length > 0 )) {
					if( ($(window).width()<768 )){
						$('.js-product-slider').each(function(){
								var swiperTrusted = new Swiper(this, {
									loop: true,
									noSwiping: false,
									spaceBetween: 16,
									watchSlidesVisibility: true,
									slidesPerView: 2,
									// breakpoints: {
									// 	992: {
									// 		slidesPerView: 3,
									// 		noSwiping: false,
									// 		noSwiping: true,
									// 	},
									// 	768: {
									// 		slidesPerView: 2,
									// 		noSwiping: false,
									// 		noSwiping: true,
									// 	},
									// },
									// navigation: {
									// 	nextEl: this.querySelector('.js-s-next'),
									// 	prevEl: this.querySelector('.js-s-prev'),
									//  },
								});
						});
					}
				}
			});

			function init_main_sliders(){
				if (($(".js-main-slider").length > 0 )) {			
						$('.js-main-slider').each(function(){
								var main = new Swiper(this, {
									loop: true,
									noSwiping: false,
									spaceBetween: 0,
									watchSlidesVisibility: true,
									slidesPerView: 1,
									navigation: {
											nextEl: this.querySelector('.js-main-next'),
											prevEl: this.querySelector('.js-main-prev'),
									},
								});
						});				
				}
			}

			init_main_sliders();

			var video_sliders = new Swiper('.js-video-slider', {
				loop: true,
				noSwiping: true, 
				spaceBetween: 20,
				// watchSlidesVisibility: true,
				slidesPerView: 4,
				breakpoints: {
					992: {
						slidesPerView: 3,
						noSwiping: false,
						noSwiping: true,
					},
					768: {
						slidesPerView: 2,
						noSwiping: false,
						noSwiping: true,
					},
				},
				navigation: {
					nextEl: '.js-video-next',
					prevEl: '.js-video-prev',
				},
			});

			if (($(".js-content-slider").length > 0 )) {

				$('.js-content-slider').each(function(){
					var swiperTrusted = new Swiper(this, {
							loop: true,
							noSwiping: false,
							spaceBetween: 20,
							watchSlidesVisibility: true,
							slidesPerView: 4,
							breakpoints: {
								992: {
									slidesPerView: 3,
									noSwiping: false,
									noSwiping: true,
								},
								768: {
									slidesPerView: 2,
									noSwiping: false,
									noSwiping: true,
								},
								580: {
									slidesPerView: 2,
									noSwiping: false,
									noSwiping: true,
								},
							},
							navigation: {
								nextEl: this.closest('.content-slider').querySelector('.js-s-next'),
								prevEl: this.closest('.content-slider').querySelector('.js-s-prev'),
							}, 
						});
				});
			}

			$('.js-load-more').on('click',function(e){
					e.preventDefault();
					let button = $(this);
					console.log(button);
					let paged = button.data('paged');
					let max  = button.data('max');	
					let posttype  = button.data('posttype');
					let resultdiv  = button.data('resultdiv');
					let taxonomy_id  = button.data('taxonomy_id');
					let taxonomy_name  = button.data('taxonomy_name');
					let postcount  = button.data('postcount');
					let data={
						'max': max, 
						'paged': paged,		
						'posttype': posttype,	
						'postcount': postcount,
						'taxonomy_id': taxonomy_id,
						'taxonomy_name': taxonomy_name,
						'action': 'load_more_product'
					};
					console.log(data);
					$.ajax({
						type: 'POST',
						url: '/hiring/wp-admin/admin-ajax.php',
						data: data, 
						error: function (jqXHR, exception) {
						},
						beforeSend : function ( xhr ) {		
							button.text('Загружается...'); 
						},
						success : function( data ){
							button.html('<span> Загрузить еще </span>');
							console.log(data);
							paged++;

							if (paged == max) {
								button.parent().hide();
							}
							if (data) {
								jQuery('.'+resultdiv+' .col-lg-3:last').after(data);
								button.html('<span> Загрузить еще </span>');
								button.attr('data-paged', paged);
								console.log(data);
								
							} else {
								button.hide();
							}
						}
					});
			});

			function setPosterUrl() {

						let videoID, posterUrl;
						
						var images = [
								'maxresdefault.jpg',
								// 'sddefault.jpg',
								//'hqdefault.jpg',
								// 'mqdefault.jpg'
								// 'default.jpg'
						];  
						
						
						let video_items=document.querySelectorAll('.video-item');
						
						video_items.forEach(function(item, index){
						
								let url=item.dataset.vid;
						
								console.log(item);
								console.log(url);
						
								videoID = YouTubeGetID(url);
						
								posterUrl = 'https://i.ytimg.com/vi/'+ videoID +'/'+ images[0];
							
								item.style.backgroundImage ='url('+posterUrl+')';
						});
						
						
			}
						
			setPosterUrl();
							
			function YouTubeGetID(url) {
				var ID = '';
				url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
				if (url[2] !== undefined) {
						ID = url[2].split(/[^0-9a-z_\-]/i);
						ID = ID[0];
				} else {
						ID = url;
				}
				return ID;
			}
			
			Fancybox.bind("[data-fancybox]", { });

			$('.js-open-contact-box').on('click',function(e){
				$('.contact-box').toggleClass('opened');
				$('.search-box').removeClass('opened');
			});

			$('.js-open-search').on('click',function(e){

				$('.search-box').toggleClass('opened');
				$('.contact-box').removeClass('opened');
			});	
			
			$('.js-anchor').on('click',function(e){
				e.preventDefault();
				let _href = $(this).attr("href");
				if(!_href)  _href = $(this).data("anchor");
				
				$("html, body").animate({
					scrollTop: ($(_href).offset().top-100) + "px"
				});
				
			});





		$.fn.textAnimation = function( animation_speed, text_speed, animation ){
			var text, i = 0;
			var $this = $(this);
			
			// store text and clear
			text = $this.text();
			$this.css('white-space', 'pre');
			$this.html('');
			
			var addLetter = setInterval(function(){
				$this.append('<div class="text_animation" style="display: inline-block; animation: ' + animation + ' ' + animation_speed + 'ms forwards">' + text[i] + '</div>');
				i++;
				if (i == text.length) clearInterval(addLetter);
			}, text_speed);
		}

		

var temp = $('.bounce_in_animation').text();
var i = 1;

$('.bounce_in_animation').textAnimation(400, 500, 'bounceIn');



$('.tab-btn').on('click', function() {
	var $this = $(this); 
	var target = $this.attr('data-tab');
	var parent = $this.closest('.tabs');
	parent.find('.tab').removeClass("active");
	parent.find('.tab-btn').removeClass('active')
	$this.addClass("active");
	
	parent.find('.tab').hide();
	parent.find('.tab[data-tab="'+target+'"]').fadeIn();
	init_main_sliders();
});





if (($(".js-service-slider-init").length > 0 )) {
		var service = new Swiper(".js-service-slider-init", {
				loop: true,
				noSwiping: false,
				spaceBetween: 20,
				slidesPerView: 4,
				breakpoints: {
					992: {
						slidesPerView: 3,
						noSwiping: false,
						noSwiping: true,
					},
					768: {
						slidesPerView: 2,
						noSwiping: false,
						noSwiping: true,
					},
					580: {
						slidesPerView: 1,
						noSwiping: false,
						noSwiping: true,
					},
				},
				navigation: {
					nextEl: ".s-next",
					prevEl: ".s-prev",
				}, 
			});
}


if (($(".js-trust-slider-init").length > 0 )) {
	var service = new Swiper(".js-trust-slider-init", {
			loop: true,
			noSwiping: false,
			spaceBetween: 20,
			slidesPerView: 4,
			breakpoints: {
				992: {
					slidesPerView: 3,
					noSwiping: false,
					noSwiping: true,
				},
				768: {
					slidesPerView: 2,
					noSwiping: false,
					noSwiping: true,
				},
				580: {
					slidesPerView: 2,
					noSwiping: false,
					noSwiping: true,
				},
			},
			// navigation: {
			// 	nextEl: ".s-next",
			// 	prevEl: ".s-prev",
			// }, 
		});
}

if (($(".js-team-slider-init").length > 0 )) {
	var service = new Swiper(".js-team-slider-init", {
			loop: true,
			spaceBetween: 20,
			slidesPerView: 4,
			autoplay: {
				delay: 4000,
			},
			breakpoints: {
				992: {
					slidesPerView: 3,
					noSwiping: false,
					noSwiping: true,
				},
				768: {
					slidesPerView: 2,
					noSwiping: false,
					noSwiping: true,
				},
				580: {
					slidesPerView: 2,
					noSwiping: false,
					noSwiping: true,
				},
			},
			// navigation: {
			// 	nextEl: ".s-next",
			// 	prevEl: ".s-prev",
			// }, 
		});
}


$('.faq-items .faq-item').on("click",function(){
	$(this).toggleClass("active");
	$(this).parent().find('.faq-item__body').slideToggle();
});

});

