$(function () {

// Ввод только цифр
	$('.js-number').keypress(function(e){
		let charCode = (e.which) ? e.which : e.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
		  return false;
		}
	});

// Деактивация кнопки отправки формы при снятии галочки с чекбокса согласия
	$('body').on('change', '.js-agree', function () {
		let $this = $(this);
		let btn = $this.closest('.js-form').find('.submit');
		if ($this.is(':checked')) {
			btn.removeAttr("disabled");
		} else {
			btn.attr("disabled", true);
		}
	});


// Валидация формы
	let formValidate = function() {

		let form = $('.js-form');

		form.each(function(){
			let thisForm = $(this);
			let url = thisForm.attr('action');
			let cat = thisForm.data('cat');
			thisForm.validate({
				focusInvalid: false,
				highlight: function(element) {
				  $(element).closest(".form-field").addClass('form-field--error');
				},
				unhighlight: function(element) {
				  $(element).closest(".form-field").removeClass('form-field--error');
				},
				submitHandler: function(form, event){
					event.preventDefault();
						let data = new FormData(),
							formParams = thisForm.serializeArray();

						$.each(thisForm.find('.js-input-file'), function(i, tag) {
							$.each($(tag)[0].files, function(i, file) {
								data.append(tag.name, file);
							});
						});

						$.each(formParams, function(i, val) {
							data.append(val.name, val.value);
						});

						
						console.log(thisForm.find('input[name=construct_img]').val());
					

						// data.append('key', 'value');


						if(thisForm[0].querySelector("input[name=file]")) {

						var ins =thisForm[0].querySelector("input[name=file]").files.length;

						for (var x = 0; x < ins; x++) {
									data.append("files[]",thisForm[0].querySelector("input[name=file]").files[x]);								
							}
						}

						
						// var ins = document.getElementById('file-inputs').files.length;

						// for (var x = 0; x < ins; x++) {
						// 		formData.append("inputfile[]", document.getElementById('file-inputs').files[x]);
						// }

						console.log(data);

					/* это нужно будет вывеcти после успешной отправки формы,
					это здесь только для демонстарации выхода попапа */

						thisForm.find('button').prop('disabled', false);
						thisForm[0].reset();

						$('.popup').removeClass('is-show');

						let $select2 = $('.js-select');
						if ($select2.length) {
							$select2.each(function () {
								$(this).val('').trigger('change');
							})
						}
						//окно благодарности
						if (typeof thisForm.data('thank') !== 'undefined' ) {
							let idThank = thisForm.data('thank');
							$(idThank).addClass('is-show');
						}
					/* ******* */
					$.ajax({
						url: '/wp-admin/admin-ajax.php',
						type: 'POST',
						processData: false, 
						contentType: false,
						data: data,
						beforeSend: function (data) {

							thisForm.find('button').attr('disabled', 'disabled');

						},
						success: function (data) {

							if (data['error']) {
								console.log('error');
							} else {
								setTimeout(function () {
									thisForm.find('button').prop('disabled', false);
									thisForm[0].reset();
									$('.popup').removeClass('is-show');

									let $select2 = $('.js-select');
									if ($select2.length) {
										$select2.each(function () {
											$(this).val('').trigger('change');
										})
									}
									console.log(data); 

									const $elem = $('.js-popup[data-block="'+thisForm.data('thank')+'"]');
									$elem.addClass('is-show');


								}, 500);
							}
						},
						error: function (xhr, ajaxOptions, thrownError) {
							console.log(xhr.status);
							console.log(thrownError);
						}
					});
					return false;
				}
			});

		});
	};

	formValidate();

	window.formValidate = formValidate;

	$B.on('click', 'label.error',function() {
		$(this).hide().siblings('input,textarea').focus();

	});

	$B.on('keyup', 'input',function(){
		$(this).siblings('label.error').hide();
	});

	$B.on('change', '.js-select', function(){
		console.log('rfd') 
		$(this).closest('.form-field').removeClass('form-field--error');
	})

	$('.popup-thank').on('click', function(){
		$(this).removeClass('is-show');
	});

});


