$(function () {

	let $popup = $('.js-popup');
	let $popupCloseBtn = $('.js-popup-close');
	let lastOpenedPopup = null;
	let scrollPosition;

	$('body').on('click','.js-popup-link',function(e){
		e.preventDefault();
		let $this = $(this);
		let target = $this.attr('data-link');
		openPopup(target,$this);
		if (typeof $this.data('scrollto') !== 'undefined' ) {
			setTimeout(function () {
				let form = $('.js-ajax-wrapper').find('form');
				if (form.length) {
					form.find('input:not([type="hidden"])').first().focus();
				}
			}, 1500);
		}
		return false;
	});

	// openPopup('getprice-modal');
	function openPopup(target,btn) {
		const $elem = $('.js-popup[data-block="'+target+'"]');
		$popup.removeClass('is-show');
		$elem.addClass('is-show');
		scrollPosition = $(window).scrollTop();

		let pouptitle = btn.attr('data-pouptitle');
		if(pouptitle){
			$elem.find('.modal-title').text(pouptitle);
			$elem.find('input[name=title]').val(pouptitle);
		} 

		let servicetitle = btn.attr('data-servicetitle');
		if(servicetitle){
			$elem.find('.js-service-name').text(servicetitle);
			$elem.find('input[name=servicename]').val(servicetitle);
		}

		if(btn.hasClass('set-build-modal')){
			let totalprice=$('.js-common-price').text(); 
			let brus=$('.product-option.active').find('.product-option__desc').text()+'. ';
			let fund=$('.cal-items--fund .cal-item.active').find('.cal-item__desc').text()+'. ';
			let roof=$('.cal-items--roof .cal-item.active').find('.cal-item__desc').text();

			$elem.find('.js-modal-product-price').text(totalprice);
			$elem.find('input[name=price]').val(totalprice);
			$elem.find('.js-modal-product-desc').text(brus+fund+roof); 
			$elem.find('input[name=about]').val(brus+fund+roof);
		}

		if($('#myCanvas').length){ 
			$elem.find('input[name=construct_img]').val(document.getElementById('myCanvas').toDataURL())
		}

	}

	window.openPopup = openPopup;

	$popupCloseBtn.on('click',function(){
		let $this = $(this);
		let $popup = $this.closest('.js-popup');

		$popup.removeClass('is-show');
		$popup.find('.js-iframe').attr('src','');
		// location.hash = '';
		if (scrollPosition !== "undefined") {
			$(window).scrollTop(scrollPosition);
			scrollPosition = undefined;
		}

		if (lastOpenedPopup === 'href') {
			history.back();
			let scrollPositionLast = $(window).scrollTop();
			// setTimeout(function(){
			// 	location.hash = ' '
			// }, 500);
			lastOpenedPopup = null;
		}

	});

	function getEventItem(item,elem) {
		$.ajax({
			url: item,
			type: "GET",
			success: function(data) {
				if ($(data).length !== 0) {
					elem.find('.js-ajax-wrapper').html($(data).hide().fadeIn(1000));

					setTimeout(function () {
						formValidate();
						initSelect();
						inputMask();
						initSlider();
						// initSelectInput();
						// initSelectSearch();
					}, 1000);

				}
			}
		});
	}


	// function initSlider(){
	// 	let popupMainSliderSelector = '.js-popup-slider';

	// 	let popupSliderOptions = {
	// 		loop: false,
	// 		speed: 1000,
	// 		initialSlide: 0,
	// 		autoHeight: true,
	// 		grabCursor: false,
	// 		simulateTouch: true,
	// 		watchSlidesProgress: true,
	// 		pagination: {
	// 			el: '.swiper-pagination',
	// 			clickable: true
	// 		},
	// 		navigation: {
	// 			nextEl: '.js-popup-next',
	// 			prevEl: '.js-popup-prev'
	// 		},
	// 		on: {
	// 			touchStart: function () {
	// 				const isIE = navigator.appVersion.indexOf('Edge') > -1;
	// 				if (isIE) return;
	// 				let swiper = this;
	// 				for (let i = 0; i < swiper.slides.length; i++) {
	// 				  swiper.slides[i].style.transition = '';
	// 				}
	// 			},
	// 		}
	// 	};

	// 	  let popupMainSlider = new Swiper(popupMainSliderSelector, popupSliderOptions);
	// }

});
