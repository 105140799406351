import 'jquery-viewport-checker';
import { TweenLite, Linear, gsap } from "gsap";


$(function () {




	const parallaxContainer = document.querySelector('.site-wrapper')
	const parallaxItems = document.querySelectorAll('.parallax') 
	const fixer = 0.003 
	document.addEventListener('mousemove', function (event) {
		if(!parallaxContainer){return}
		const pageX =
			event.pageX - parallaxContainer.getBoundingClientRect().width * 0.5
 
		const pageY =
			event.pageY - parallaxContainer.getBoundingClientRect().height * 0.5
 
		for (let i = 0; i < parallaxItems.length; i++) {
			const item = parallaxItems[i]
			const speedX = item.getAttribute('data-speed-x')
			const speedY = item.getAttribute('data-speed-y')
			const speedZ = item.getAttribute('data-speed-z')
			TweenLite.set(item, {
				x: (item.offsetLeft + pageX * speedX) * fixer,
				y: (item.offsetTop + pageY * speedY) * fixer,
				z: (item.offsetTop + pageY * speedZ) * 0.02,
			})
		}
	})

  var doAnimations = function() {
    
    var offset = $(window).scrollTop() + $(window).height(),
        $animatables = $('.animatable');
    
    if ($animatables.length == 0) {
      $(window).off('scroll', doAnimations);
    }
    
	$animatables.each(function(i) {
       var $animatable = $(this);
			if (($animatable.offset().top + $animatable.height() - 20) < offset) {
        $animatable.removeClass('animatable').addClass('animated');
			}
    });

};

var tmpImg = new Image() ;
tmpImg.src = $('.introbg-img img').attr('src') ;
tmpImg.onload = function() {
	$('.introbg-img').removeClass('animatable').addClass('animated');
	
} ;



  
$(window).on('scroll', doAnimations);


});
